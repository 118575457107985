import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => {
  const [isSticky, setIsSticky] = useState(false)
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(true)

  useEffect(() => {
    let oldScroll = 0
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        // only hide if navigation is collapsed
        if (isNavigationCollapsed) {
          if (window.scrollY === 0) {
            // reset menu
            setIsSticky(false)
          } else {
            // only show menu when scrolling up
            setIsSticky(oldScroll > window.scrollY)
          }

          oldScroll = window.scrollY
        }
      }
    }
  }, [isNavigationCollapsed])

  function menuButtonClick() {
    // toggle navigation
    setIsNavigationCollapsed(!isNavigationCollapsed)
    return true
  }

  function menuItemClick() {
    // hide navigation
    setIsNavigationCollapsed(true)
    // hide menu
    setIsSticky(false)
    return true
  }

  return (
    <header
      className={`bg-gradient-to-r from-gray-900 to-indigo-700 ${
        isSticky ? "sticky w-full top-0 z-50 shadow-xl" : ""
      } print:shadow-none`}
    >
      <div className="container mx-auto print:max-w-none">
        <nav className="flex items-center justify-between flex-wrap p-6 lg:px-0 print:p-0">
          <div className="flex flex-grow items-center flex-shrink-0 text-white mr-20">
            <span className="font-sans font-bold text-xl tracking-tight print:text-9xl print:uppercase print:text-gray-800">
              <a href="https://klabbet.se">{siteTitle}</a>
            </span>
          </div>
          <div className="block lg:hidden print:hidden">
            <button
              className="flex items-center px-3 py-2 border rounded text-white border-teal-400 hover:border-white"
              onClick={menuButtonClick}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Meny</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={`w-full lg:block flex-grow lg:flex lg:w-auto ${
              isNavigationCollapsed ? "hidden" : ""
            }`}
          >
            <div className="text-md lg:flex lg:flex-row lg:flex-grow  lg:justify-between">
              <a
                href="#bio"
                className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4"
                onClick={menuItemClick}
              >
                Om mig
              </a>
              <a
                href="#services"
                className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4"
                onClick={menuItemClick}
              >
                Tjänster
              </a>
              <a
                href="#experience"
                className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4"
                onClick={menuItemClick}
              >
                Erfarenhet
              </a>
              <a
                href="#availability"
                className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4"
                onClick={menuItemClick}
              >
                Tillgänglighet
              </a>

              <a
                href="#contact"
                className="block mt-4 lg:inline-block lg:mt-0 text-white"
                onClick={menuItemClick}
              >
                Kontakt
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
