import React from "react"

export default function Footer() {
  return (
    <footer className="bg-gray-900  text-white text-sm print:hidden">
      <div className="container mx-auto py-4 flex flex-col lg:flex-row">
        <span className="flex-1 text-center lg:text-left">
          Denna sida har inga kakor{" "}
          <span role="img" aria-label="cookie">
            🍪
          </span>
        </span>
        <span className="text-center">
          Copyright © {new Date().getUTCFullYear()} Klabbet{" "}
        </span>
        <a
          href="https://www.klabbet.com"
          className="text-white flex-1 text-center lg:text-right text-pink-400"
        >
          &raquo; Go to English
        </a>
      </div>
    </footer>
  )
}
