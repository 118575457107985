import React from "react"
import logoWhite from "./Logo-white.svg"

export default function Banner() {
  return (
    <div className="flex flex-row h-96 justify-center bg-gradient-to-r from-gray-900 to-indigo-700  mb-8 shadow-xl print:hidden">
      <img src={logoWhite} className="h-full p-10" alt="Klabbet logo" />
    </div>
  )
}
